import React from 'react'
import Logo from "../../assets/img/logo.svg";


function Main() {
    return (
        <>
            <footer className="footer-bg footer-p" style={{backgroundColor: "#00173c"}}>
                <div className="footer-top">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-12 col-lg-12 col-sm-12"
                                 style={{
                                     textAlign: "center"
                                 }}
                            >
                                <img
                                    className={"logo-footer"}
                                    src={Logo} alt="logo"/>

                            </div>

                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <div className="footer-widget mb-30">
                                    <div
                                        style={{
                                            textAlign: "center"
                                        }}
                                        className="f-widget-title">
                                        <h2>I nostri contatti</h2>
                                    </div>
                                    <div className="f-contact">
                                        <ul>
                                            <li style={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                                <i className="icon fal fa-map-marker-check"/>
                                                <span

                                                    style={{
                                                        minWidth: "300px"
                                                    }}>                                              Via Scafati 208, 80050
                                        <br/> Santa Maria la Carità (NA)
                                    </span>
                                            </li>
                                            <li style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                // alignItems: "center"
                                            }}>
                                                <i className="icon fal fa-phone"/>
                                                <span

                                                    style={{
                                                        minWidth: "300px"
                                                    }}> Recapito telefonico:
                                        <br/>
                                        +39 0818742019
                                    </span>
                                            </li>
                                            <li style={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                                <i className="icon fal fa-clock"/>
                                                <span

                                                    style={{
                                                        minWidth: "300px"
                                                    }}>
                                        Lunedi - Venerdi:
                                        <br/>
                                                    15:00 - 20.00
                                        </span>
                                            </li>
                                            <li style={{
                                                display: "flex",
                                                justifyContent: "end"
                                            }}>
                                                <i className="icon fal fa-envelope"/>
                                                <span

                                                    style={{
                                                        minWidth: "175px"
                                                    }}>
                                                 Indirizzo mail:
                                        <br/>
                                     sicignano-studio.odontoiatrico@hotmail.com
                                    </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="copy-text">
                                    Copyright © 2024 Sicignano Studio Odontroiatrico.
                                    <br/>All rights reserved.
                                </div>
                            </div>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4">
                                <div className="footer-social">
                                    <a href='https://www.facebook.com/'>
                                        <i className="fab fa-facebook-f"/>
                                    </a>
                                    <a href='https://www.instagram.com/'>
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Main