import React from "react";
import Featuresservices from "../../assets/img/features/services-img-details2.png";
import FeaturesservicesTwo from "../../assets/img/studio.jpg";

function Ourservices() {
    const data = [
        {
            id: 1,
            name: "Igiene e profilassi",
            img: "assets/img/icon/fe-icon04.png",
        },
        {
            id: 2,
            name: "Ortognatodonzia",
            img: "assets/img/icon/fe-icon05.png",
        },
        {
            id: 3,
            name: "Chirurgia Orale",
            img: "assets/img/icon/fe-icon06.png",
        },
        {
            id: 4,
            name: "Protesi Fissa",
            img: "assets/img/icon/fe-icon07.png",
        },
    ];


    const dataone = [
        {
            id: 5,
            name: "Conservativa",
            img: "assets/img/icon/fe-icon01.png",
        },
        {
            id: 6,
            name: "Endodonzia",
            img: "assets/img/icon/fe-icon01.png",
        },
        {
            id: 7,
            name: "Implantologia",
            img: "assets/img/icon/fe-icon06.png",
        },
        {
            id: 8,
            name: "Protesi Mobile",
            img: "assets/img/icon/fe-icon07.png",
        },
    ];


    return (
        <>
            <section id="service-details2" className="service-details-two pt-120 pb-105 p-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title center-align mb-50 text-center">
                                {/*<h5>Our Servicesss</h5>*/}
                                <h2>Servizi offerti</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="about-content s-about-content">
                                <ul className="sr-tw-ul text-right">
                                    {data.map((item) => (
                                        <li
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                                alignItems: "center"
                                            }}
                                            key={item.id}>
                                            <div className="text"
                                                 style={{
                                                     display: "flex",
                                                     minWidth: "200px"
                                                 }}>
                                                <h4>{item.name}</h4>
                                            </div>
                                            <div className="icon-right">
                                                <img src={item.img} alt="icon01"/>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 text-center d-none d-lg-block">
                            <div className="sd-img">
                                <img src={Featuresservices} alt="img"/>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="about-content s-about-content">
                                <ul className="sr-tw-ul">
                                    {dataone.map((item) => (
                                        <li key={item.id}>
                                            <div className="icon">
                                                <img src={item.img} alt="icon01"/>
                                            </div>
                                            <div className="text"
                                                 style={{
                                                     display: "flex",
                                                     minWidth: "200px"
                                                 }}>
                                                <h4>{item.name}</h4>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-area about-p pb-120 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center align-items-center"
                         style={{
                             textAlign: "center"
                         }}
                    >
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div
                                className="s-about-img p-relative  wow fadeInLeft animated"
                                data-animation="fadeInLeft"
                                data-delay=".4s"
                            >
                                <img src={FeaturesservicesTwo} alt="img"/>
                                {/*<div className="about-text second-about">*/}
                                {/*    <span>50</span>*/}
                                {/*    <p>Years of Experience</p>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div
                                className="about-content s-about-content  wow fadeInRight  animated"
                                data-animation="fadeInRight"
                                data-delay=".4s"
                            >
                                <div className="about-title second-title pb-25">
                                    <h2>La nostra storia</h2>
                                </div>
                                <p
                                    style={{
                                        fontSize: "13px"
                                    }}
                                >
                                    Lo studio odontoiatrico "Sicignano" nasce nel 2023 dal progetto del dott. Carmine
                                    Sicignano, laureatosi nel 2022 con il massimo dei voti e lode presso l'Università
                                    degli Studi della Campania "Luigi Vanvitelli".
                                    <br/>
                                    Il Dott. Sicignano è attualmente specializzando in Chirurgia Orale presso l'UOC di
                                    Chirurgia Orale e Stomatologia dell'Università degli Studi della Campania "Luigi
                                    Vanvitelli".
                                    <br/>

                                    Lo studio è ubicato a Santa Maria la Carità, ed è stato progettato secondo le
                                    indicazioni "Industria 4.0", come uno studio altamente tecnologico, moderno e
                                    funzionale.

                                    <br/>
                                    Al suo interno infatti, sarà possibile effettuare in sede esami radiografici di
                                    primo e secondo livello e verranno utilizzati materiali e metodiche aggiornati
                                    secondo le ultime linee guida ministeriali.
                                </p>
                                <div className="slider-btn mt-20">
                                    {/*<Link to="about.html" className="btn ss-btn smoth-scroll">*/}
                                    {/*    Read More*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Ourservices;
