const sviluppo = {
    API_ENDPOINT_URL_SERVER_MAIL: 'https://5p8slj28cj.execute-api.eu-central-1.amazonaws.com/latest',
};

const produzione = {
    API_ENDPOINT_URL_SERVER_MAIL: 'https://yrq0qnl8rh.execute-api.eu-central-1.amazonaws.com/latest',
};

const getEnv = () => {

    switch (process.env.REACT_APP_ENV?.trim()) {
        case 'sviluppo':
            return sviluppo
        case 'produzione':
            return produzione
        default:
            return {
                API_ENDPOINT_URL_SERVER_MAIL: 'http://localhost:8080',
            };

    }
}

export const env = getEnv()
