import React, {useState} from 'react'
import Logo from '../../assets/img/logo/logo_senzascritta.svg'
import {Link} from 'react-router-dom'


function Main() {

    const [mobile, setmobile] = useState(false);
    const [home, setHome] = useState(false);
    const [Services, setServices] = useState(false)
    const [Pages, setPages] = useState(false)
    const [Blog, setBlog] = useState(false)

    return (
        <>
            <header className="header-area header-three">
                <div className="header-top second-header d-none d-md-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-8 col-md-8 d-none d-lg-block">
                                <div className="header-cta">
                                    <ul>
                                        <li>
                                            <i className="icon fal fa-envelope"/>
                                            <span>info@example.com</span>
                                        </li>
                                        <li>
                                            <i className="icon fal fa-phone"/>
                                            <span>+91-7052-101-786</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 d-none d-lg-block text-right">
                                <div className="header-social">
                                    <span>
                                        <Link to='https://www.facebook.com/' title="Facebook">
                                            <i className="fab fa-facebook-f"/>
                                        </Link>
                                        <Link to='https://www.instagram.com/' title="LinkedIn">
                                            <i className="fab fa-instagram"/>
                                        </Link>
                                        <Link to='https://twitter.com/' title="Twitter">
                                            <i className="fab fa-twitter"/>
                                        </Link>
                                        <Link to='https://www.youtube.com/' title="Twitter">
                                            <i className="fab fa-youtube"/>
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="header-sticky" className="menu-area sticky-menu" style={{backgroundColor: "white"}}>
                    <div className="container">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src={Logo} alt="logo"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-7">
                                    <div className="main-menu text-right text-xl-right">
                                        <nav id="mobile-menu">
                                            <ul>
                                                <li className="has-sub">
                                                    <Link to="/">Home</Link>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="/servizi">I nostri servizi</Link>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="/faqs">Faq</Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div
                                    className="col-xl-3 col-lg-3 text-right d-none d-lg-block mt-25 mb-25 text-right text-xl-right">
                                    <div className="login">
                                        <ul>
                                            <li>
                                                <div className="second-header-btn">
                                                    <Link to="/contattaci" className="btn">
                                                        Contattaci
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mobile-menu mean-container">
                                        <div className="mean-bar">
                                            <Link to="#" onClick={() => setmobile(!mobile)}
                                                  className={`meanmenu-reveal ${mobile && "meanclose"}`} style={{
                                                right: "0px",
                                                left: "auto",
                                                textAlign: "center",
                                                textIndent: "0px",
                                                fontSize: "18px"
                                            }}>
                                                {mobile ? "X" : <span><span><span></span></span></span>}
                                            </Link>
                                            {mobile &&
                                                <nav className="mean-nav">
                                                    <ul style={{display: "block"}}>
                                                        <li className="has-sub">
                                                            <Link to="/"> Home </Link>
                                                            <Link className={`mean-expand ${mobile && "mean-clicked"}`}
                                                                  to="/" onClick={() => {
                                                                setmobile(!mobile)
                                                            }} style={{fontSize: 18}}>
                                                            </Link>
                                                        </li>
                                                        <li className="has-sub">
                                                            <Link to="/servizi">I nostri servizi</Link>
                                                            <Link className={`mean-expand ${mobile && "mean-clicked"}`}
                                                                  to="/servizi" onClick={() => {
                                                                setmobile(!mobile)
                                                            }} style={{fontSize: 18}}>
                                                            </Link>
                                                        </li>
                                                        <li className="has-sub">
                                                            <Link to="/faqs">Faq</Link>
                                                            <Link className={`mean-expand ${mobile && "mean-clicked"}`}
                                                                  to="/faqs" onClick={() => {
                                                                setmobile(!mobile)
                                                            }} style={{fontSize: 18}}>
                                                            </Link>
                                                        </li>
                                                        <li className="has-sub">
                                                            <Link to="/contattaci">Contattaci</Link>
                                                            <Link className={`mean-expand ${mobile && "mean-clicked"}`}
                                                                  to="/contattaci" onClick={() => {
                                                                setmobile(!mobile)
                                                            }} style={{fontSize: 18}}>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Main