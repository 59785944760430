import React, {useState} from "react";

function Faq() {
    const [activeFaq, setActiveFaq] = useState(null);  // Inizialmente nessun accordion è aperto

    const Drop = [
        {
            Que: " Ogni quanto effettuare visite di controllo dal dentista?",
            Answer: "Si consiglia di effettuare una visita di controllo dal dentista ogni sei mesi circa. É molto importante effettuare visite periodiche di controllo in modo che il dentista riesca a prevenire e intercettare l’insorgenza di problematiche come carie, tartaro o irritazione alle gengive.",
        },
        {
            Que: "Ogni quanto effettuare un'igiene orale professionale e gli strumenti per la pulizia dentale a casa sostituiscono la pulizia dal dentista?",
            Answer: "In farmacia si trovano frequentemente strumenti per effettuare la pulizia dentale fai da te, ma comunque una pulizia professionale resta sempre il metodo più efficace, in particolare in soggetti parodontopatici e per chi soffre di tartaro, i prodotti per la pulizia fai da te possono fare poco rispetto a una pulizia completa dal dentista, che andrebbe effettuata ogni sei mesi o quantomeno una volta all'anno.",
        },
        {
            Que: "Come si sbiancano i denti?",
            Answer: "Lo sbiancamento dei denti è un trattamento cosmetico che può essere realizzato soltanto su denti naturali, sia vitali che devitalizzati, ma non su denti protesizzati oppure otturazioni.\n" +
                "Il processo che agisce sulla schiaritura dello smalto avviene attraverso l’utilizzo di paste contenenti perossido di idrogeno o di carbamide.\n" +
                "Lo sbiancamento di denti devitalizzati invece, si pratica in studio inserendo pasta sbiancante nel dente protetta da una otturazione provvisoria. L’otturazione verrà rimossa ed eventualmente applicata nuovamente la settimana successiva fino ad ottenere il risultato ottenuto.",
        },
        {
            Que: "Cosa sono le carie?",
            Answer: "Le carie sono lesioni dei denti causate dagli acidi prodotti dai batteri, in seguito al metabolismo degli zuccheri. L’acido corrode lo smalto dei denti fino a raggiungere il tessuto sottostante, la dentina. La dentina è più tenera e quindi più aggredibile dai batteri, che la attraversano fino a raggiungere la camera pulpare, la cavità che contiene vasi sanguigni e nervi. E' importante pertanto effettuare periodicamente visite dal dentista per rimuovere le eventuali carie quando esse sono ancora in una fase iniziale.",
        },
        {
            Que: "Cos'è la parodontite?",
            Answer: "La malattia parodontale è un’infiammazione dei tessuti di supporto del dente (osso, legamento e cemento radicolare) e conduce progressivamente alla perdita sia ossea che gengivale.\n" +
                "Conosciuta anche come piorrea, questa patologia può portare, in stadio avanzato, alla mobilità e successiva perdita dei denti. La causa principale è la placca batterica che si accumula fra denti e gengive.",
        },
        {
            Que: "É doloroso inserire un impianto?",
            Answer: "Grazie ai moderni anestetici locali, l'intervento di inserimento implantare risulta totalmente indolore. Anche il post-operatorio risulta essere generalmente sereno. \n" +
                "Occasionalmente il paziente può lamentare un leggero fastidio o dolore post - operatorio. Tale fastidio dura in genere uno/due giorni e viene facilmente gestito mediante la prescrizione di farmaci idonei a controllare il dolore.",
        },
        {
            Que: "Cos'è l'ortopantomografia?",
            Answer: "Con il termine ortopantomografia si intende quell’esame radiografico che ci permette di vedere entrambe le arcate dentarie in una sola radiografia. Oggi grazie al progresso digitale, è possibile effettuare ortopantomografie ad una dose estremamente bassa di radiazioni.",
        },
        {
            Que: "Qual è la causa più frequente dell’alitosi?",
            Answer: "Le cause possono essere molteplici, tuttavia la cattiva igiene orale, il fumo, una alimentazione non equilibrata, possono avere delle conseguenze determinanti nella presenza di alitosi.",
        },
        {
            Que: "Perché è necessario curare i denti da latte?",
            Answer: "Nei bambini è importante mantenere inalterata, nel corso della crescita nell’attesa della permuta (cambio dei denti), la lunghezza e la forma delle arcate dentarie, in modo da permettere l’alloggiamento dei corrispondenti denti permanenti. La presenza del dente deciduo permette lo sviluppo del dente permanente corrispondente in arcata e dell’osso alveolare dei mascellari deputati ad accoglierlo. Pertanto è sempre consigliabile curare i denti da latte ( a meno che essi non siano prossimi alla caduta).",
        },
        {
            Que: "Dopo l'inserimento degli impianti devo restare senza protesi?",
            Answer: "Generalmente è possibile realizzare una protesi provvisoria, mobile o fissa, ancorata ai denti adiacenti.\n" +
                "Nei casi in cui non siano presenti altri denti, si dovrà ricorrere ad una protesi totale, sempre quando non si possa realizzare un carico immediato.",
        },
    ];

    return (
        <>
            <section id="faq" className="faq-area pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    {Drop.map((items, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading${index}`}>
                                                <h2 className="mb-0">
                                                    <button
                                                        className={`faq-btn ${
                                                            activeFaq !== index ? "collapsed" : ""
                                                        }`}
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#collapse${index}`}
                                                        onClick={() => {
                                                            setActiveFaq(activeFaq === index ? null : index);
                                                        }}
                                                    >
                                                        {items.Que}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div
                                                id={`collapse${index}`}
                                                className={`collapse ${activeFaq === index ? "show" : ""}`}
                                                aria-labelledby={`heading${index}`}
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="card-body">
                                                    {items.Answer}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faq;
