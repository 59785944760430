import React, {useEffect} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom';
import Header from '../components/Header/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import About from '../components/About-Us/Main'
import Contact from '../components/Contact-Us/Main'
import ServiceDetails from '../components/Servicesdeatils/Main'
import Faq from '../components/PagesMen/Faq/Main'

function Index() {

    const location = useLocation();
    const path = location.pathname
    useEffect(() => {
        window.scroll(0, 0)
    }, [path]);

    return (
        <>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contattaci" element={<Contact/>}/>
                <Route path="/servizi" element={<ServiceDetails/>}/>
                <Route path="/faqs" element={<Faq/>}/>
            </Routes>
            <Footer/>
        </>
    )
}

export default Index