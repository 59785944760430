import React from 'react'
import {Link} from 'react-router-dom'
import SliderOne from "../../assets/img/logo_soloscritta.svg";


function breadcrumb({subtitle, title, subtitledown}) {
    return (
        <>
            <section className="breadcrumb-area d-flex align-items-center" style={{backgroundColor: `#f5f5f5`}}>
                {/*<img src={SliderOne} alt="banner"/>*/}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-4">
                            <div className="breadcrumb-wrap text-left">
                                <div className="breadcrumb-title"

                                >
                                    <h2 style={{
                                        color: "#8ab5e1"
                                    }}> {subtitle} </h2>
                                    <div className="breadcrumb-wrap">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to="/"
                                                          style={{
                                                              color: "#92c56e"
                                                          }}
                                                    > {title} </Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page"
                                                    style={{
                                                        color: "#92c56e !important"
                                                    }}

                                                >
                                                    {subtitledown}
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8">

                            <div
                                className="logo-breadcrumb"
                                style={{
                                    backgroundImage: `url(${SliderOne})`,
                                }}/>

                        </div>
                    </div>

                </div>


            </section>

        </>
    )
}

export default breadcrumb