import React from 'react'
import Make from '../MenContact-Us/Make'
import Bredcm from '../Menhome/breadcrumb'
import Map from '../MenContact-Us/Map'

function Main() {
    return (
        <>
            <Bredcm
                subtitle="Contattaci"
                title="Home"
                subtitledown="contattaci"
            />
            <Make/>
            <Map/>
        </>
    )
}

export default Main