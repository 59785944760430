import React, {useRef} from 'react'
import {env} from "../../configs/EnvironmentConfig";
import axios from "axios";
import Swal from "sweetalert2";

function Make() {

    const formRef = useRef(null)

    const sendMail = async (form) => {

        // Controlla se i campi obbligatori sono stati compilati
        if (!form.nome || !form.oggetto || !form.mail || !form.descrizione) {
            Swal.fire({
                icon: "error",
                title: "Attenzione!",
                text: "Per favore, compila tutti i campi obbligatori.",
            });
            return;
        }

        // Controlla il formato dell'email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(form.mail)) {
            Swal.fire({
                icon: "error",
                title: "Attenzione!",
                text: 'Inserisci un indirizzo email valido.'
            });
            return;
        }

        const api = env.API_ENDPOINT_URL_SERVER_MAIL + "/sicignanostudioodontoiatrico/send-mail"
        console.log(api)
        console.log(form)

        try {
            const response = await axios.post(api, form)

            if (response.status === 200) {
                formRef.current.reset(); // Resettare il form dopo l'invio

                Swal.fire({
                    icon: "success",
                    title: "Il tuo messaggio è stato inviato correttamente!",
                    timer: 2000
                });

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Attenzione!",
                    text: 'Il messaggio non è stato inviato correttamente. Riprovare!'
                });
            }
        } catch (e) {
            console.error(e)
            Swal.fire({
                icon: "error",
                title: "Attenzione!",
                text: 'Il messaggio non è stato inviato correttamente. Riprovare!'
            });
        }


    }
    return (
        <>
            <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-4 order-2">
                            <div className="contact-info">
                                <div className="single-cta pb-30 mb-30 wow fadeInUp animated"
                                     data-animation="fadeInDown animated" data-delay=".2s">
                                    <div className="f-cta-icon">
                                        <i className="far fa-map"/>
                                    </div>
                                    <h5>Indirizzo</h5>
                                    <p style={{
                                        marginLeft: "90px"
                                    }}>
                                        Via Scafati 208, 80050 <br/>
                                        Santa Maria la Carità (NA)
                                    </p>
                                </div>
                                <div className="single-cta pb-30 mb-30 wow fadeInUp animated"
                                     data-animation="fadeInDown animated" data-delay=".2s">
                                    <div className="f-cta-icon">
                                        <i className="far fa-phone"/>
                                    </div>
                                    <h5>Telefono</h5>
                                    <p>
                                        0818742019
                                    </p>
                                </div>
                                <div
                                    className="single-cta pb-30 mb-30 wow fadeInUp animated"
                                    data-animation="fadeInDown animated" data-delay=".2s">
                                    <div className="f-cta-icon">
                                        <i className="far fa-clock"/>
                                    </div>
                                    <h5>Orari di Apertura</h5>
                                    <p>
                                        Lun - Ven: 15:00 - 20.00 <br/>
                                    </p>
                                </div>
                                <div
                                    className="single-cta pb-30 mb-30 wow fadeInUp animated"
                                    data-animation="fadeInDown animated" data-delay=".2s">
                                    <div className="f-cta-icon">
                                        <i className="far fa-envelope-open"/>
                                    </div>
                                    <h5>Email</h5>
                                    <p style={{
                                        marginLeft: "90px"
                                    }}>
                                        sicignano-studio.odontoiatrico@hotmail.com
                                        <br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 order-1">
                            <div className="contact-bg02">
                                <div className="section-title center-align">
                                    <h2>Contattaci</h2>
                                </div>
                                <form
                                    ref={formRef}
                                    onSubmit={(e) => {
                                        e.preventDefault(); // Evita il comportamento predefinito del form (cambiare l'URL)
                                        const formData = new FormData(e.target); // Cattura i dati del modulo
                                        const formDataObject = Object.fromEntries(formData.entries()); // Converti i dati del modulo in un oggetto JavaScript

                                        sendMail(formDataObject); // Passa i dati del modulo alla funzione sendMail
                                    }}
                                    className="contact-form mt-30">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input type="text" id="nome" name="nome"
                                                       placeholder="Nome e Cognome"
                                                       required=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <input type="text" id="mail" name="mail" placeholder="Email"
                                                       required=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <input type="text" id="telefono" name="telefono" placeholder="Telefono"
                                                       required=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <input type="text" id="oggetto" name="oggetto" placeholder="Oggetto"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-message mb-30">
                                                <textarea name="descrizione" id="descrizione" cols={30} rows={10}
                                                          placeholder="Scrivi il tuo messaggio.." defaultValue={""}/>
                                            </div>
                                            <div className="slider-btn">
                                                <button className="btn ss-btn" data-animation="fadeInRight"
                                                        data-delay=".8s">
                                                    <span>Invia</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Make