import React, {useState} from 'react'
import profilassi from '../../assets/img/igiene_profilassi.jpg'
import profilassi2 from '../../assets/img/igiene_profilassi_2.jpg'
import conservativa from '../../assets/img/conservativa.jpg'
import conservativa2 from '../../assets/img/conservativa_2.jpg'
import endodonzia1 from '../../assets/img/endodonzia1.jpg'
import endodonzia2 from '../../assets/img/endodonzia2.jpg'
import implantologia2 from '../../assets/img/implantologia2.png'
import implantologia1 from '../../assets/img/implantologia1.png'
import ortodonzia1 from '../../assets/img/ortodonzia1.png'
import ortodonzia2 from '../../assets/img/ortodonzia2.png'
import chirugia1 from '../../assets/img/chirurgia1.jpg'
import chirugia2 from '../../assets/img/chirurgia2.jpg'
import protesifissa1 from '../../assets/img/protesifissa1.jpg'
import protesifissa2 from '../../assets/img/protesifissa2.jpg'
import protesimobile1 from '../../assets/img/protesimobile1.jpg'
import protesimobile2 from '../../assets/img/protesimobile2.jpg'
import {Link} from 'react-router-dom'
import feicon01 from "../../assets/img/icon/fe-icon01.png";
import feicon05 from "../../assets/img/icon/fe-icon05.png";
import feicon07 from "../../assets/img/icon/fe-icon07.png";
import feicon04 from "../../assets/img/icon/fe-icon04.png";
import feicon06 from "../../assets/img/icon/fe-icon06.png";

function First() {

    const [serviceIndex, setServiceIndex] = useState(0);

    const services = [
        {
            icon: feicon01,
            first_foto: profilassi,
            second_foto: profilassi2,
            title: 'IGIENE E PROFILASSI',
            description: 'Branca dell\'odontoiatria che si occupa della rimozione meccanica di placca e tartaro mediante l\'utilizzo di strumenti ad ultrasuoni e della motivazione all\'igiene orale, con l\'illustrazione delle corrette metodiche di spazzolamento dentario e dei corretti presidi da utilizzare per l\'igiene orale domiciliare. Va sottolineato che per mantenere una corretta salute orale, le sedute di igiene orale professionale dovrebbero essere effettuate a scadenza semestrale.'
        },
        {
            first_foto: conservativa,
            second_foto: conservativa2,
            icon: feicon05,
            title: 'CONSERVATIVA',
            description: 'L’odontoiatria conservativa è una branca dell’Odontoiatria che si occupa principalmente della cura della carie dentale. La sua finalità è quella di conservare in bocca i denti colpiti dalla carie o fratture, eliminando dapprima il tessuto cariato per poi ripristinare forma, funzione ed estetica grazie a materiali dentali simili ai denti naturali.'
        },
        {
            icon: feicon07,
            first_foto: endodonzia1,
            second_foto: endodonzia2,
            title: 'ENDODONZIA',
            description: 'All’interno del dente, sotto i tessuti duri meglio noti come smalto e dentina, vi è una cavità all’interno della quale vi è un tessuto molle chiamato polpa. La polpa contiene vasi sanguigni, nervi e tessuto connettivo e crea i tessuti circostanti duri del dente durante lo sviluppo.\n' +
                'Il trattamento endodontico (noto e anche come devitalizzazione) è necessario quando la polpa dentaria si infiamma irreversibilmente o si infetta.\n' +
                'L’infiammazione o l’infezione della polpa può verificarsi per svariati motivi: carie ripetute, ripetute procedure odontoiatriche o anche per via di una crepa o di una frattura del dente. Delle volte, un forte trauma può causare danni irreversibili alla polpa, anche se il dente non ha alcun segno apparente.\n' +
                'Se l’infiammazione e/o infezione pulpare non viene adeguatamente curata, può portare nel tempo dolore, talvolta acuto, e ricorrenti ascessi.'
        },
        {
            icon: feicon04,
            first_foto: implantologia1,
            second_foto: implantologia2,
            title: 'IMPLANTOLOGIA',
            description: 'L’implantologia è quella branca dell’odontoiatria che ha come scopo la soluzione del problema dell’edentulia, parziale o completa, sia dal punto di vista funzionale che estetico. La soluzione è rappresentata dalla estrazione dei denti non più idonei a svolgere la funzione masticatoria e la loro sostituzione con impianti dentali endossei, ossia che vanno inseriti direttamente nell’osso (mandibolare o mascellare). Agli impianti dentali è possibile fissare una o più corone di differenti materiali e costi a seconda delle necessità oppure utilizzarli per fissare e stabilizzare protesi removibili o come solida base per protesi totali fisse.'
        },
        {
            icon: feicon06,
            first_foto: ortodonzia1,
            second_foto: ortodonzia2,
            title: 'ORTOGNATODONZIA',
            description: 'Tra le branche dell’odontoiatria, l’ortognatodonzia (o ortodonzia) è quella che si preoccupa di studiare soluzioni atte a risolvere il non corretto posizionamento dei denti all’interno del cavo orale allo scopo di ripristinare quanto più possibile la corretta funzione masticatoria che incide notevolmente anche sulle articolazioni muscolo-scheletriche e sulla postura. La risoluzione di tali problemi possono essere espletati attraverso apparecchi ortodontici fissi, o apparecchi ortodontici mobili. Negli ultimi anni le nuove tecnologie hanno permesso l\'avvento anche della cosiddetta "Ortodonzia Invisibile" mediante l\'utilizzo di mascherine trasparenti, per ridurre al minimo il disagio estetico, soprattutto in pazienti adulti.'
        },
        {
            icon: feicon06,
            first_foto: protesifissa1,
            second_foto: protesifissa2,
            title: 'PROTESI FISSA',
            description: 'La protesi dentaria è una delle branche dell’odontoiatria e si occupa di realizzare il dispositivo protesico più adatto alle esigenze del paziente per la sostituzione di uno o più elementi dentali mancanti.\n' +
                'La protesi fissa comprende protesi cementate su denti naturali (coronte, ponti, intarsi,) o protesi cementate o avvitate su impianti dentari.\n' +
                'La realizzazione di tali protesi dipenderà da diversi fattori, quali la stabilità degli elementi dentari da sfruttare come pilastro e la presenza di una quota di osso per l\'inserimento degli impianti.'
        }, {
            icon: feicon06,
            first_foto: chirugia1,
            second_foto: chirugia2,
            title: 'CHIRURGIA ORALE',
            description: 'La chirurgia orale (identificata anche con la dizione di chirurgia odontostomatologica) si occupa principalmente dell\'estrazione di denti gravemente compromessi (cioè non più recuperabili con terapie conservative o endodontiche), di interventi ortognatodontici (mancanza di spazio in arcata) e dell\'estrazione di residui radicolari; si occupa, inoltre, della exeresi chirurgica di elementi dentari inclusi o semi-inclusi nella compagine ossea, come gli ottavi (denti del giudizio) in disodontiasi; di pertinenza chirurgica orale sono anche le frenulectomie, le alveoloplastiche, le gengivo-plastiche, l\'asportazione dell\'apice di denti coinvolti in processi infiammatori quando non trattabili con la sola endodonzia (apicectomia) e l\'asportazione di cisti del distretto maxillo-mandibolare.\n'
        }, {
            icon: feicon06,
            first_foto: protesimobile1,
            second_foto: protesimobile2,
            title: 'PROTESI MOBILE',
            description: 'Protesi dentaria è una delle branche dell’odontoiatria e si occupa di realizzare il dispositivo protesico più adatto alle esigenze del paziente per la sostituzione di uno o più elementi dentali mancanti.\n' +
                'La protesi mobile comprende quella che solitamente viene individuata nella classica dentiera che viene rimossa durante il riposo notturno, o da un tipo di protesi "scheletrata", che sfrutta la presenza degli elementi dentari residui come supporto.'
        },
    ];

    const chooseService = (index) => {
        console.log(index)
        setServiceIndex(index)
    }


    return (
        <>
            <div className="about-area5 about-p p-relative">
                <div className="container pt-120 pb-90">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 order-1">
                            <aside className="sidebar services-sidebar">
                                <div className="sidebar-widget categories">
                                    <div className="widget-content">
                                        <ul className="services-categories">
                                            {services.map((servizio, index) => (
                                                    <li className={index === serviceIndex ? "active" : ""}>
                                                        <Link onClick={() => chooseService(index)}> {servizio.title}</Link>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>

                        <div className="col-lg-8 col-md-12 col-sm-12 order-2">
                            <div className="service-detail">
                                <div className="content-box">
                                    <h2> {services[serviceIndex].title} </h2>
                                    <p>
                                        {services[serviceIndex].description}
                                    </p>

                                    <div className="two-column">
                                        <div className="row">
                                            <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                                <figure className="image">
                                                    <img src={services[serviceIndex].first_foto} alt=""/>
                                                </figure>
                                            </div>
                                            <div className="text-column col-xl-6 col-lg-12 col-md-12">
                                                <figure className="image">
                                                    <img src={services[serviceIndex].second_foto} alt=""/>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<h3>Why Choose This Service</h3>*/}
                                    {/*<p>*/}
                                    {/*    Complete account of the systems and expound the actually teachings*/}
                                    {/*    of the great explorer of the truth, the master-builder of human*/}
                                    {/*    uts happiness. No one rejects, dislikes, or avoids pleasure*/}
                                    {/*    itself, because it is pleasure, but because those who do not know*/}
                                    {/*    how to pursue pleasure rationally encounter consequences that are*/}
                                    {/*    extremely.*/}
                                    {/*</p>*/}
                                    {/*<p>*/}
                                    {/*    Complete account of the systems and expound the actually teachings*/}
                                    {/*    of the great explorer of the truth, the master-builder of human*/}
                                    {/*    uts happiness. No one rejects, dislikes, or avoids pleasure*/}
                                    {/*    itself, because it is pleasure, but because those who do not know*/}
                                    {/*    how to pursue pleasure rationally.*/}
                                    {/*</p>*/}

                                    {/*<div className="product-info-tabs">*/}

                                    {/*    <div className="prod-tabs tabs-box">*/}

                                    {/*        <ul className="tab-btns tab-buttons clearfix">*/}
                                    {/*            <li data-tab="#prod-details" className="tab-btn active-btn">*/}
                                    {/*                Precautions*/}
                                    {/*            </li>*/}
                                    {/*            <li data-tab="#prod-spec" className="tab-btn">*/}
                                    {/*                Intelligence*/}
                                    {/*            </li>*/}
                                    {/*            <li data-tab="#prod-reviews" className="tab-btn">*/}
                                    {/*                Specializations*/}
                                    {/*            </li>*/}
                                    {/*        </ul>*/}

                                    {/*        <div className="tabs-content">*/}

                                    {/*            <div className="tab " id="prod-details">*/}
                                    {/*                <div className="content">*/}
                                    {/*                    <p>*/}
                                    {/*                        aaaa*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}

                                    {/*            <div className="tab active-tab" id="prod-spec">*/}
                                    {/*                <div className="content">*/}
                                    {/*                    <p>*/}
                                    {/*                        Suspendisse laoreet at nulla id auctor. Maecenas in dui*/}
                                    {/*                        cursus, lacinia nisl non, blandit lorem. Aliquam vel*/}
                                    {/*                        risus hendrerit, faucibus nisl a, porta sapien. Etiam*/}
                                    {/*                        iaculis mattis quam, nec iaculis velit feugiat quis.*/}
                                    {/*                        Pellentesque sed feugiat dui, ac euismod leo.*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="tab" id="prod-reviews">*/}
                                    {/*                <div className="content">*/}
                                    {/*                    <p>*/}
                                    {/*                        Suspendisse laoreet at nulla id auctor. Maecenas in dui*/}
                                    {/*                        cursus, lacinia nisl non, blandit lorem. Aliquam vel*/}
                                    {/*                        risus hendrerit, faucibus nisl a, porta sapien. Etiam*/}
                                    {/*                        iaculis mattis quam, nec iaculis velit feugiat quis.*/}
                                    {/*                        Pellentesque sed feugiat dui, ac euismod leo.*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default First