import React from 'react'
import Iframe from 'react-iframe'


function Map() {
    return (
        <>
            <div className="map fix" style={{background: "#f5f5f5"}}>
                <div className="container-flud">
                    <div className="row">
                        <div className="col-lg-12">
                            <Iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2138.0346939093574!2d14.511279928398253!3d40.72599236039337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133bbcfbe2be1eaf%3A0x90645fa0fb8b4a5!2sStr.%20Scafati%2C%20208%2C%2080050%20Santa%20Maria%20la%20Carit%C3%A0%20NA!5e0!3m2!1sit!2sit!4v1711635561914!5m2!1sit!2sit"
                                width={600}
                                height={450}
                                style={{border: "0"}}
                                loading="lazy">
                            </Iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Map